export const ACTIONS = {
  IS_BUY_SCENARIO: "IS_BUY_SCENARIO",
  IS_RENT_SCENARIO: "IS_RENT_SCENARIO",

  WORK_DAY_INCREMENT: "WORK_DAY_INCREMENT",
  WORK_DAY_DECREMENT: "WORK_DAY_DECREMENT",
  WORK_DAY_INPUT: "WORK_DAY_INPUT",

  PATIENT_PER_DAY_DECREMENT: "PATIENT_PER_DAY_DECREMENT",
  PATIENT_PER_DAY_INCREMENT: "PATIENT_PER_DAY_INCREMENT",
  PATIENT_PER_DAY_INPUT: "PATIENT_PER_DAY_INPUT",

  IS_LADOCAINE: "IS_LADOCAINE",
  IS_NOT_LADOCAINE: "IS_NOT_LADOCAINE",
  LADOCAINEQNT: "LADOCAINEQNT",

  PROCEDURE_EXTRA_FEE: "PROCEDURE_EXTRA_FEE",

  TIME_PER_PATIENT_DECREMENT: "TIME_PER_PATIENT_DECREMENT",
  TIME_PER_PATIENT_INCREMENT: "TIME_PER_PATIENT_INCREMENT",
  TIME_PER_PATIENT_INPUT: "TIME_PER_PATIENT_INPUT",

  AVERAGE_PROCEDURE_COST_DECREMENT: "AVERAGE_PROCEDURE_COST_DECREMENT",
  AVERAGE_PROCEDURE_COST_INCREMENT: "AVERAGE_PROCEDURE_COST_INCREMENT",
  AVERAGE_PROCEDURE_COST_INPUT: "AVERAGE_PROCEDURE_COST_INPUT",
};
